/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    DiscordAuthResponse,
    DiscordAuthResponseFromJSON,
    DiscordAuthResponseToJSON,
    DiscordInfoResponse,
    DiscordInfoResponseFromJSON,
    DiscordInfoResponseToJSON,
    GetConnectedAccountBody,
    GetConnectedAccountBodyFromJSON,
    GetConnectedAccountBodyToJSON,
    GetConnectedAccountResponse,
    GetConnectedAccountResponseFromJSON,
    GetConnectedAccountResponseToJSON,
    InlineObject3,
    InlineObject3FromJSON,
    InlineObject3ToJSON,
    RewardDailyXpBody,
    RewardDailyXpBodyFromJSON,
    RewardDailyXpBodyToJSON,
} from '../models';

export interface DiscordAuthRedirectRequest {
    inlineObject3: InlineObject3;
}

export interface GetConnectedAccountRequest {
    getConnectedAccountBody: GetConnectedAccountBody;
}

export interface RewardDailyXpRequest {
    rewardDailyXpBody: RewardDailyXpBody;
}

/**
 * DiscordApi - interface
 * @export
 * @interface DiscordApiInterface
 */
export interface DiscordApiInterface {
    /**
     * Disconnects the discord account associated with the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscordApiInterface
     */
    disconnectDiscordAccountRaw(): Promise<runtime.ApiResponse<boolean>>;

    /**
     * Disconnects the discord account associated with the user
     */
    disconnectDiscordAccount(): Promise<boolean>;

    /**
     * Fetches the Discord OAuth2 URL for account linking.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscordApiInterface
     */
    discordAuthRaw(): Promise<runtime.ApiResponse<DiscordAuthResponse>>;

    /**
     * Fetches the Discord OAuth2 URL for account linking.
     */
    discordAuth(): Promise<DiscordAuthResponse>;

    /**
     * Handles the redirect from Discord OAuth2 and processes the authorization code.
     * @param {InlineObject3} inlineObject3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscordApiInterface
     */
    discordAuthRedirectRaw(requestParameters: DiscordAuthRedirectRequest): Promise<runtime.ApiResponse<boolean>>;

    /**
     * Handles the redirect from Discord OAuth2 and processes the authorization code.
     */
    discordAuthRedirect(requestParameters: DiscordAuthRedirectRequest): Promise<boolean>;

    /**
     * 
     * @summary Retrieves the user ID associated with a given Discord member ID
     * @param {GetConnectedAccountBody} getConnectedAccountBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscordApiInterface
     */
    getConnectedAccountRaw(requestParameters: GetConnectedAccountRequest): Promise<runtime.ApiResponse<GetConnectedAccountResponse>>;

    /**
     * Retrieves the user ID associated with a given Discord member ID
     */
    getConnectedAccount(requestParameters: GetConnectedAccountRequest): Promise<GetConnectedAccountResponse>;

    /**
     * Fetches the Discord account linked with the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscordApiInterface
     */
    getDiscordAccountRaw(): Promise<runtime.ApiResponse<DiscordInfoResponse>>;

    /**
     * Fetches the Discord account linked with the user
     */
    getDiscordAccount(): Promise<DiscordInfoResponse>;

    /**
     * 
     * @summary Rewards daily XP to the specified Discord member
     * @param {RewardDailyXpBody} rewardDailyXpBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscordApiInterface
     */
    rewardDailyXpRaw(requestParameters: RewardDailyXpRequest): Promise<runtime.ApiResponse<number>>;

    /**
     * Rewards daily XP to the specified Discord member
     */
    rewardDailyXp(requestParameters: RewardDailyXpRequest): Promise<number>;

}

/**
 * no description
 */
export class DiscordApi extends runtime.BaseAPI implements DiscordApiInterface {

    /**
     * Disconnects the discord account associated with the user
     */
    async disconnectDiscordAccountRaw(): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/discord/account/disconnect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Disconnects the discord account associated with the user
     */
    async disconnectDiscordAccount(): Promise<boolean> {
        const response = await this.disconnectDiscordAccountRaw();
        return await response.value();
    }

    /**
     * Fetches the Discord OAuth2 URL for account linking.
     */
    async discordAuthRaw(): Promise<runtime.ApiResponse<DiscordAuthResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/link-discord`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscordAuthResponseFromJSON(jsonValue));
    }

    /**
     * Fetches the Discord OAuth2 URL for account linking.
     */
    async discordAuth(): Promise<DiscordAuthResponse> {
        const response = await this.discordAuthRaw();
        return await response.value();
    }

    /**
     * Handles the redirect from Discord OAuth2 and processes the authorization code.
     */
    async discordAuthRedirectRaw(requestParameters: DiscordAuthRedirectRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.inlineObject3 === null || requestParameters.inlineObject3 === undefined) {
            throw new runtime.RequiredError('inlineObject3','Required parameter requestParameters.inlineObject3 was null or undefined when calling discordAuthRedirect.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/link-discord/callback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject3ToJSON(requestParameters.inlineObject3),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Handles the redirect from Discord OAuth2 and processes the authorization code.
     */
    async discordAuthRedirect(requestParameters: DiscordAuthRedirectRequest): Promise<boolean> {
        const response = await this.discordAuthRedirectRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieves the user ID associated with a given Discord member ID
     */
    async getConnectedAccountRaw(requestParameters: GetConnectedAccountRequest): Promise<runtime.ApiResponse<GetConnectedAccountResponse>> {
        if (requestParameters.getConnectedAccountBody === null || requestParameters.getConnectedAccountBody === undefined) {
            throw new runtime.RequiredError('getConnectedAccountBody','Required parameter requestParameters.getConnectedAccountBody was null or undefined when calling getConnectedAccount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/discord/integration/get_connected_account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetConnectedAccountBodyToJSON(requestParameters.getConnectedAccountBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConnectedAccountResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves the user ID associated with a given Discord member ID
     */
    async getConnectedAccount(requestParameters: GetConnectedAccountRequest): Promise<GetConnectedAccountResponse> {
        const response = await this.getConnectedAccountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetches the Discord account linked with the user
     */
    async getDiscordAccountRaw(): Promise<runtime.ApiResponse<DiscordInfoResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/discord/account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscordInfoResponseFromJSON(jsonValue));
    }

    /**
     * Fetches the Discord account linked with the user
     */
    async getDiscordAccount(): Promise<DiscordInfoResponse> {
        const response = await this.getDiscordAccountRaw();
        return await response.value();
    }

    /**
     * Rewards daily XP to the specified Discord member
     */
    async rewardDailyXpRaw(requestParameters: RewardDailyXpRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.rewardDailyXpBody === null || requestParameters.rewardDailyXpBody === undefined) {
            throw new runtime.RequiredError('rewardDailyXpBody','Required parameter requestParameters.rewardDailyXpBody was null or undefined when calling rewardDailyXp.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/discord/integration/reward_daily_xp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RewardDailyXpBodyToJSON(requestParameters.rewardDailyXpBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Rewards daily XP to the specified Discord member
     */
    async rewardDailyXp(requestParameters: RewardDailyXpRequest): Promise<number> {
        const response = await this.rewardDailyXpRaw(requestParameters);
        return await response.value();
    }

}
