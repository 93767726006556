/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OWGameEventType,
    OWGameEventTypeFromJSON,
    OWGameEventTypeFromJSONTyped,
    OWGameEventTypeToJSON,
    OWGameImage,
    OWGameImageFromJSON,
    OWGameImageFromJSONTyped,
    OWGameImageToJSON,
    OWUserStat,
    OWUserStatFromJSON,
    OWUserStatFromJSONTyped,
    OWUserStatToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains games that has Overwolf game events support
 * @export
 * @interface OWGame
 */
export interface OWGame {
    /**
     * The Overwolf ID of the game
     * @type {string}
     * @memberof OWGame
     */
    gameId: string;
    /**
     * The title of the game
     * @type {string}
     * @memberof OWGame
     */
    name: string;
    /**
     * The description of the game.
     * @type {string}
     * @memberof OWGame
     */
    description: string;
    /**
     * 
     * @type {Array<OWGameEventType>}
     * @memberof OWGame
     */
    events?: Array<OWGameEventType>;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    devourGameLogo?: string;
    /**
     * 
     * @type {OWGameImage}
     * @memberof OWGame
     */
    bannerImage?: OWGameImage;
    /**
     * 
     * @type {OWGameImage}
     * @memberof OWGame
     */
    landingImage?: OWGameImage;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    thumbnailImage?: string;
    /**
     * The flag to check if the game has event support by Overwolf
     * @type {boolean}
     * @memberof OWGame
     */
    isEventSupported: boolean;
    /**
     * The flag to check if the game is supported by DevourGO
     * @type {boolean}
     * @memberof OWGame
     */
    isEventSupportReady?: boolean;
    /**
     * 
     * @type {Array<OWUserStat>}
     * @memberof OWGame
     */
    userStats?: Array<OWUserStat>;
    /**
     * 
     * @type {string}
     * @memberof OWGame
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OWGame
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof OWGame
     */
    updatedAt: number;
}

export function OWGameFromJSON(json: any): OWGame {
    return OWGameFromJSONTyped(json, false);
}

export function OWGameFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWGame {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameId': json['gameId'],
        'name': json['name'],
        'description': json['description'],
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(OWGameEventTypeFromJSON)),
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'devourGameLogo': !exists(json, 'devourGameLogo') ? undefined : json['devourGameLogo'],
        'bannerImage': !exists(json, 'bannerImage') ? undefined : OWGameImageFromJSON(json['bannerImage']),
        'landingImage': !exists(json, 'landingImage') ? undefined : OWGameImageFromJSON(json['landingImage']),
        'thumbnailImage': !exists(json, 'thumbnailImage') ? undefined : json['thumbnailImage'],
        'isEventSupported': json['isEventSupported'],
        'isEventSupportReady': !exists(json, 'isEventSupportReady') ? undefined : json['isEventSupportReady'],
        'userStats': !exists(json, 'userStats') ? undefined : ((json['userStats'] as Array<any>).map(OWUserStatFromJSON)),
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function OWGameToJSON(value?: OWGame | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gameId': value.gameId,
        'name': value.name,
        'description': value.description,
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(OWGameEventTypeToJSON)),
        'logo': value.logo,
        'devourGameLogo': value.devourGameLogo,
        'bannerImage': OWGameImageToJSON(value.bannerImage),
        'landingImage': OWGameImageToJSON(value.landingImage),
        'thumbnailImage': value.thumbnailImage,
        'isEventSupported': value.isEventSupported,
        'isEventSupportReady': value.isEventSupportReady,
        'userStats': value.userStats === undefined ? undefined : ((value.userStats as Array<any>).map(OWUserStatToJSON)),
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


