/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountExperienceTransaction,
    AccountExperienceTransactionFromJSON,
    AccountExperienceTransactionFromJSONTyped,
    AccountExperienceTransactionToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOverwolfXPResponse
 */
export interface GetOverwolfXPResponse {
    /**
     * 
     * @type {Array<AccountExperienceTransaction>}
     * @memberof GetOverwolfXPResponse
     */
    transactions: Array<AccountExperienceTransaction>;
    /**
     * 
     * @type {boolean}
     * @memberof GetOverwolfXPResponse
     */
    isWeeklyLimitFeedbackDismissed?: boolean;
}

export function GetOverwolfXPResponseFromJSON(json: any): GetOverwolfXPResponse {
    return GetOverwolfXPResponseFromJSONTyped(json, false);
}

export function GetOverwolfXPResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOverwolfXPResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'transactions': ((json['transactions'] as Array<any>).map(AccountExperienceTransactionFromJSON)),
        'isWeeklyLimitFeedbackDismissed': !exists(json, 'isWeeklyLimitFeedbackDismissed') ? undefined : json['isWeeklyLimitFeedbackDismissed'],
    };
}

export function GetOverwolfXPResponseToJSON(value?: GetOverwolfXPResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'transactions': ((value.transactions as Array<any>).map(AccountExperienceTransactionToJSON)),
        'isWeeklyLimitFeedbackDismissed': value.isWeeklyLimitFeedbackDismissed,
    };
}


