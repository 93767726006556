import {AccountExperienceTransaction, OWReward, OWUserStat} from "@devour/client";
import GoVipOWQuestRewardCard from "@/components/goVip/GoVipOWQuestRewardCard";
import {useState} from "react";

interface Props {
    gameId: string;
    quests: Array<OWReward>
    userStats: Array<OWUserStat>;
    rewards: Array<AccountExperienceTransaction>;
}

export default function OverwolfGoVipQuestRewards(props: Props) {
    const [isClaimingInProgress, setIsClaimingInProgress] = useState<boolean>(false);

    return (
        <div className="overwolf-govip_rewards_quest_list">
            {props.quests.map((quest) => {
                const userStat = props.userStats?.find((stat) => stat.reward.id === quest.id);
                return (
                    <GoVipOWQuestRewardCard
                        className="overlay-card"
                        quest={quest}
                        userStat={userStat}
                        gameId={props.gameId}
                        isClaimingInProgress={isClaimingInProgress}
                        setIsClaimingInProgress={setIsClaimingInProgress}
                        key={quest.id}
                    />
                );
            })}
        </div>
    );
}
