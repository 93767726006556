import {ReactElement} from "react";

function DevourPlayLandingPage(): ReactElement {
    
    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <iframe 
                src="https://www.devourplay.com/"
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none' // Optional: removes the default iframe border
                }}
            />
        </div>
    );
}

export default DevourPlayLandingPage;

