import {AccountExperienceTransaction} from "@devour/client";
import {isDesktop} from "react-device-detect";
import GoVipOWTimeBasedRewardCard from "@/components/goVip/GoVipOWTimeBasedRewardCard";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";


interface Props {
    rewards: Array<AccountExperienceTransaction>;
}

export default function OverwolfGoVipTimeRewards(props: Props) {
    const { isOnOverwolf } = useOverwolfInterop();
    if (!props.rewards?.length) {
        return (
            <div className={isOnOverwolf ? "overwolf-govip_rewards_time_empty" : "go-vip-ow_rewards-modal_body_empty"}>
                <img
                    src={`${import.meta.env.VITE_CDN_URL}/images/govip-ow-time-rewards-modal-empty.webp`}
                    alt="Overwolf Time Rewards Empty"
                />
                <h5>Haven’t played enough?</h5>
                <span className={!isOnOverwolf && "go-vip-ow_rewards-modal_body_empty_description"}>
                    No XP yet, but the more you play, {isDesktop && <br/>}the more you earn. Jump into a game and play for an hour to earn your first XP!
                </span>
            </div>
        );
    }

    return (
        <div className={isOnOverwolf ? "overwolf-govip_rewards_time_list" : "go-vip-ow_rewards-modal_body_list"}>
            {props.rewards.map((reward: AccountExperienceTransaction) =>
                <GoVipOWTimeBasedRewardCard
                    className={isOnOverwolf && "overlay-card"}
                    reward={reward}
                    key={reward.id}/>)}
        </div>
    );
}