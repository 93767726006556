import {AccountExperienceTransaction} from "@devour/client";
import classNames from "classnames";

interface Props {
    reward: AccountExperienceTransaction;
    className?: string;
}
export default function GoVipOWTimeBasedRewardCard(props: Props) {
    function formatDate(date: number) {
        const time = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        }).format(date);

        const fullDate = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
        }).format(date);

        return `${time} | ${fullDate}`;
    }

    return (
        <div className={classNames("go-vip-ow_time-reward-card", props.className)}>
            <div>
                <div className="go-vip-ow_time-reward-card_timestamp">
                    {formatDate(props.reward.createdAt)}
                </div>
                <strong className="go-vip-ow_time-reward-card_title">
                    {props.reward.notes}
                </strong>
            </div>

            <strong className="go-vip-ow_time-reward-card_points">+ {props.reward.amount} XP</strong>
        </div>
    );
}